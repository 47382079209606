import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import microsoft_365_1 from '../../../../../../../common/src/assets/image/microsoft_365_1.png';
import microsoft_365_2 from '../../../../../../../common/src/assets/image/microsoft_365_2.png';
import microsoft_365_3 from '../../../../../../../common/src/assets/image/microsoft_365_3.png';
import microsoft_365_4 from '../../../../../../../common/src/assets/image/microsoft_365_4.png';
import microsoft_365_5 from '../../../../../../../common/src/assets/image/microsoft_365_5.png';

const Documentation = () => {
  const content = (
  <div>
    <p>
      PrintableCal can{' '}
      <Link to="/Documentation/Create-Calendar/Choose-a-Template">
        create printable calendars
      </Link>{' '}
      containing events, to-dos, and tasks that are defined in a Microsoft 365 (Office 365) account or group. Here's how to add Microsoft 365 calendar data
      sources to PrintableCal:
    </p>
    <ol className="numbered">
      <li>
        Click the <strong>Add Calendar</strong> button on the Calendar Data
        Sources step and select the <strong>Microsoft 365 / Outlook 365</strong> option.
      </li>
      <li>
        If you haven't previously connected PrintableCal to a Microsoft 365 or Office 365 account, or if your account credentials have changed, a <strong>Sign in to your account</strong> window will appear.
        Select the desired account (if listed) or click the <strong>Use another account</strong> button if your account isn't in the list. <br />
        <Image alt="" src={microsoft_365_1} style={{ width: 570 }} />
      </li>
      <li>After selecting or adding the desired account, enter your account password or login credentials and then click the <strong>Sign in</strong> button. You'll only need to do this once.<br/>
        <Image alt="" src={microsoft_365_2} style={{ width: 570 }} />
      </li>
      <li>After signing in, the <strong>Add Calendar Source</strong> window will list of all calendars, task plans, and to-do lists that your account can access. This can include group calendars and task plans that are owned by other accounts and shared with your account.<br/>
        <Image alt="" src={microsoft_365_3} style={{ width: 590 }} />
      </li>
      <li>Check the box next to each calendar, task plan, or to-do list you'd like to include in PrintableCal's output.<br/>
        <Image alt="" src={microsoft_365_4} style={{ width: 590 }} />
      </li>
      <li>If you have multiple accounts and would like to overlay data from those accounts in the same output, you can select or add a different account by clicking the account button in the bottom-left corner of the window.
        Select a previously added account from the drop-down, or click the <strong>Add Account</strong> button to add another account. After selecting an account, the window will refresh to list the calendars, task plans, and to-do lists available to that account.<br/>
        <Image alt="" src={microsoft_365_5} style={{ width: 590 }} />
      </li>
      <li>When finished, click the <strong>OK</strong> button to add the checked calendar data sources.</li>
    </ol>
  </div>
  );

  return (
  <DocumentationSection
  url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Microsoft365"
  commentsId="commentsplus_post_microsoft365"
  title="Print Microsoft 365 Calendars, To-Do Lists, and Task Plans"
  description="PrintableCal can create printable calendars containing events, to-dos, and tasks that are defined in a Microsoft 365 (Office 365) account or group."
  keywords="print Microsoft 365 calendar, print Office 365 calendar, print task plan, print group calendar"
  content={content}
  />
  );
};

export default Documentation;
